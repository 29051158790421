import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datetime from 'react-datetime';
import moment from 'moment';
import './receipt.scss';
import { ReceiptData, ReceiptContent } from '../../types/reducerTypes';
import 'react-datetime/css/react-datetime.css';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import Pagination from '../../components/pagination';

export interface Props {
  receiptData: ReceiptData;
  changeDate: any;
  downloadReceipt: any;
}

export const Report: React.FC<Props> = (props) => {
  const [fromDate, updateFromDate] = useState();
  const [toDate, updateTodate] = useState();
  const [page, setPage] = useState(0);
  const initialPage = 0;
  const maxPages = 3;

  const fromDateChange = (dt: any): void => {
    updateFromDate(dt);
    setPage(initialPage);
    if (dt && toDate) {
      props.changeDate(
        moment(dt).format('MM/DD/yyyy'),
        moment(toDate).format('MM/DD/yyyy'),
        initialPage
      );
    }
  };
  const toDateChange = (dt: any): void => {
    updateTodate(dt);
    setPage(initialPage);
    if (dt && fromDate)
      props.changeDate(
        moment(fromDate).format('MM/DD/yyyy'),
        moment(dt).format('MM/DD/yyyy'),
        initialPage
      );
  };

  const validToDate = (currentDate: any): any => {
    const currentDateMoment = moment(currentDate).format('MM/DD/yyyy');
    const fromDateMoment = moment(fromDate).format('MM/DD/yyyy');
    const rangeDate = moment(fromDate);
    rangeDate.set('month', rangeDate.month() + 3);
    const toRangeDate = moment(new Date()).isBefore(rangeDate)
      ? moment(new Date()).format('MM/DD/yyyy')
      : rangeDate.format('MM/DD/yyyy');
    return (
      moment(currentDateMoment, 'MM/DD/yyyy').isSameOrBefore(
        moment(toRangeDate, 'MM/DD/yyyy')
      ) &&
      moment(currentDateMoment, 'MM/DD/yyyy').isSameOrAfter(
        moment(fromDateMoment, 'MM/DD/yyyy')
      )
    );
  };

  const validFromDate = (currentDate: any): any => {
    const currentDateMoment = moment(currentDate).format('MM/DD/yyyy');
    const toDateMoment = moment(toDate).format('MM/DD/yyyy');
    const rangeOfToDate = moment(toDate);
    rangeOfToDate.set('month', rangeOfToDate.month() - 3);
    const previousYearMoment = moment(
      new Date(new Date().setMonth(new Date().getMonth() - 12))
    );
    const rangeDate =
      toDate && rangeOfToDate.isSameOrAfter(previousYearMoment)
        ? rangeOfToDate.format('MM/DD/yyyy')
        : previousYearMoment.format('MM/DD/yyyy');
    return (
      moment(currentDateMoment, 'MM/DD/yyyy').isSameOrBefore(
        moment(toDateMoment, 'MM/DD/yyyy')
      ) &&
      moment(currentDateMoment, 'MM/DD/yyyy').isSameOrAfter(
        moment(rangeDate, 'MM/DD/yyyy')
      )
    );
  };

  const getPageNumber = (pageNumber: number): void => {
    if (fromDate && toDate) {
      updateFromDate(fromDate);
      updateTodate(toDate);
      props.changeDate(
        moment(fromDate).format('MM/DD/yyyy'),
        moment(toDate).format('MM/DD/yyyy'),
        pageNumber
      );
    }
    setPage(pageNumber);
  };

  const renderInputCalendar = (calendarProps: any, openCalendar: any): any => {
    return (
      <div>
        <input {...calendarProps} />
        <div className="date_icon" onClick={openCalendar}>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </div>
      </div>
    );
  };
  const noDataContent =
    fromDate && toDate ? (
      <tr>
        <td colSpan={7} className="no_data">
          <div className="no_data_found">No Data Found</div>
        </td>
      </tr>
    ) : (
      <tr>
        <td colSpan={7} className="no_data">
          <div className="no_data_found"></div>
        </td>
      </tr>
    );
  return (
    <Container fluid>
      <Row>
        <Col sm={12} className="content_area bulk_receipt pr0 pt0 pb0 pl60">
          <Col md={12}>
            <div className="title_wrap">
              <div className="sub_title mb5">
                Select a date range to view results.
              </div>
            </div>
          </Col>
          <Form className="custom_form">
            <Col sm={12}>
              <Row>
                <Col sm={8}>
                  <div className="width120">
                    <Form.Group>
                      <Form.Label>From</Form.Label>
                      <div className="date_picker_area">
                        <Datetime
                          defaultValue="MM/DD/YY"
                          timeFormat={false}
                          className="custom_date_picker"
                          value={fromDate}
                          closeOnSelect={true}
                          onChange={fromDateChange}
                          isValidDate={validFromDate}
                          // @ts-ignore
                          renderInput={renderInputCalendar}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="to_separator">to</div>
                  <div className="width120">
                    <Form.Group>
                      <Form.Label>To</Form.Label>
                      <div className="date_picker_area">
                        <Datetime
                          defaultValue="MM/DD/YY"
                          timeFormat={false}
                          className="custom_date_picker"
                          value={toDate}
                          closeOnSelect={true}
                          onChange={toDateChange}
                          isValidDate={validToDate}
                          viewDate={fromDate}
                          /* NOTE: there is no other option other than bypassing TS as DateTime.d.ts dont have 'renderInput' type 
                                   PR for same is still open in github
                                   https://github.com/YouCanBookMe/react-datetime/pull/533 */
                          // @ts-ignore
                          renderInput={renderInputCalendar}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </Col>
                <Col sm={4}>
                  {props.receiptData.content.length > 0 && (
                    <Button
                      onClick={() =>
                        props.downloadReceipt(
                          moment(fromDate).format('MM/DD/yyyy'),
                          moment(toDate).format('MM/DD/yyyy')
                        )
                      }
                      className="button_primary_blue max_width_112 mt25 float-right"
                    >
                      Download
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Form>
          <Col sm={12}>
            <Table hover className="round_border mt10">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Case Number</th>
                  <th>Bill Service ID</th>
                  <th>Vehicle</th>
                  <th>Customer</th>
                  <th>Invoice</th>
                </tr>
              </thead>
              <tbody>
                {props.receiptData.content.length > 0
                  ? props.receiptData.content.map(
                      (receipt: ReceiptContent, index: any) => {
                        return (
                          <tr key={index}>
                            <td>
                              {receipt.receiptDate ? receipt.receiptDate : '-'}
                            </td>
                            <td>{receipt.caseId ? receipt.caseId : '-'}</td>
                            <td>
                              {receipt.billServiceId
                                ? receipt.billServiceId
                                : 'N/A'}
                            </td>
                            <td>
                              {receipt.vehicleDetails
                                ? receipt.vehicleDetails
                                : '-'}
                            </td>
                            <td>
                              {receipt.customerDetails
                                ? receipt.customerDetails
                                : '-'}
                            </td>
                            <td>
                              {receipt.invoiceAmount
                                ? '$' + receipt.invoiceAmount
                                : '-'}
                            </td>
                          </tr>
                        );
                      }
                    )
                  : noDataContent}
              </tbody>
            </Table>
          </Col>
          <Pagination
            totalPages={props.receiptData?.meta?.totalPages}
            getPageNumber={getPageNumber}
            reSetPage={page}
            maxPages={maxPages}
          ></Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default Report;
