import { ReceiptData } from '../../types/reducerTypes';
import { GET_RECEIPT_SUCCESS, CLEAR_RECEIPT_DATA } from './receiptActionTypes';

const receiptdata: ReceiptData = {
  content: [],
  meta: {
    totalElements: 0,
    totalPages: 0,
  },
};

export const receiptReducer = (
  state = receiptdata,
  action: any = { type: '', payload: '' }
): object => {
  switch (action.type) {
    case GET_RECEIPT_SUCCESS:
      return {
        ...state,
        content: [...action.response.content],
        meta: action.response.meta,
      };
    case CLEAR_RECEIPT_DATA:
      return {
        content: [action],
      };
    default:
      return state;
  }
};
