import { put, takeLatest } from 'redux-saga/effects';
import {
  HIDE_LOADING,
  SHOW_LOADING,
  ERROR_REMOVE,
  ERROR_OCCUR,
} from '../config/configActionTypes';
import {
  GET_RECEIPT_DATA,
  GET_RECEIPT_SUCCESS,
  CLEAR_RECEIPT_DATA,
  DOWNLOAD_RECEIPT,
} from './receiptActionTypes';
import {
  GET_RECEIPT,
  HTTP_GET,
  GET_RECEIPT_DOWNLOAD,
  RESPONSE_TYPE_BLOB,
} from '../../constants/apiEndPoint';
import API from '../../utils/API';
import { downloadFiles } from '../../utils/commonUtility';
import moment from 'moment';
import { sendMessage, EventName, postMessage } from '../../utils/eventHandler';
import translation from '../../utils/language';

function* getReceiptData({ params }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_GET,
      GET_RECEIPT(
        params.partnerCode,
        params.fromDate,
        params.toDate,
        params.page
      ),
      false,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      response.data.meta = {
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
      };
      yield put({ type: CLEAR_RECEIPT_DATA, response: null });
      yield put({ type: GET_RECEIPT_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    postMessage.type = EventName.ANGULAR_SHOW_ERROR;
    postMessage.data = {
      error: error.response.data.message,
    };
    sendMessage(postMessage);
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}

function* getDownloadReceipt({ params }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_GET,
      GET_RECEIPT_DOWNLOAD(params.partnerCode, params.fromDate, params.toDate),
      false,
      true,
      RESPONSE_TYPE_BLOB
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      const date = moment(new Date()).format('MMDDyyyy');
      const time = moment(new Date()).format('HHmm');
      const fileName = `CaseReceipt_${date}_${time}.zip`;
      downloadFiles(fileName, response.data);
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    postMessage.type = EventName.ANGULAR_SHOW_ERROR;
    postMessage.data = {
      error: translation.GENERAL_ERROR_MSG,
    };
    sendMessage(postMessage);
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}

function* receiptSaga(): any {
  yield takeLatest(GET_RECEIPT_DATA, getReceiptData);
  yield takeLatest(DOWNLOAD_RECEIPT, getDownloadReceipt);
}

export default receiptSaga;
