import * as React from 'react';
import { SPINNER_IMG } from '../utils/images';

interface Props {
  show: boolean;
}

const Loader: React.FC<Props> = React.memo(({ show }) => (
  <div className={`loader ${show ? 'show' : 'hide'}`}>
    <img src={SPINNER_IMG} title="" alt="" />
  </div>
));

export default Loader;

Loader.displayName = 'Loader';
