import { put, takeLatest } from 'redux-saga/effects';
import { AUTH_EXPIRE, ERROR_OCCUR } from '../config/configActionTypes';

function* authExpire(error: any): any {
  yield put({ type: ERROR_OCCUR, response: error.response.data });
}

function* loginSaga(): any {
  yield takeLatest(AUTH_EXPIRE, authExpire);
}

export default loginSaga;
