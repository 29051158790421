export const HOSTURL = process.env.REACT_APP_API_HOST;
export const NEW_HOSTURL = process.env.REACT_APP_NEW_API_HOST;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const INDUSTRY = process.env.REACT_APP_INDUSTRY;
export const DEVICE = process.env.REACT_APP_DEVICE;
export const APP_TYPE = process.env.REACT_APP_APP_TYPE;
export const HTTP_POST = `POST`;
export const HTTP_PUT = `PUT`;
export const HTTP_GET = `GET`;
export const LOGIN_API = `${HOSTURL}/ops/login`;
export const RESPONSE_TYPE_BLOB = 'blob';

export const GET_RECEIPT = (
  partnerCode: string,
  fromDate: string,
  toDate: string,
  page: number
): string =>
  `${NEW_HOSTURL}/invoicing-v1/invoicing/receipts/${partnerCode}?from=${fromDate}&to=${toDate}&size=10&page=${page}`;

export const GET_RECEIPT_DOWNLOAD = (
  partnerCode: string,
  fromDate: string,
  toDate: string
): string =>
  `${NEW_HOSTURL}/invoicing-v1/invoicing/receipts/${partnerCode}/download?from=${fromDate}&to=${toDate}`;
