import { AuthInitialStateTypes } from '../../types/reducerTypes';
import { LOGIN_SUCCESS, ANGULAR_AUTH_SUCCESS } from './loginActionTypes';
const authInitialState: AuthInitialStateTypes = {
  authData: {
    id: 0,
    authToken: '',
    partnerId: 0,
    partners: [],
    name: '',
    uid: '',
    channel: '',
    chatChannel: '',
    authRole: '',
    authEntity: '',
    facility: '',
  },
};

export const authenticationReducer = (
  state = authInitialState,
  action: any = { type: '', payload: '' }
): object => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        authData: action.response.data[0],
      };
    case ANGULAR_AUTH_SUCCESS:
      return {
        ...state,
        authData: {
          ...state.authData,
          authToken: action.params,
        },
      };
    default:
      return state;
  }
};
