import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

export interface Props {
  totalPages: number;
  getPageNumber: any;
  reSetPage: number;
  maxPages: number;
}

export const PaginationCommon: React.FC<Props> = (props) => {
  const startPage = 1;
  const [page, setPage] = useState(0);
  const [defaultPages, setDefaultPages] = useState([] as number[]);
  const [currentPage, setCurrentPage] = useState(1);

  const addNext = (): void => {
    defaultPages.push(defaultPages[defaultPages.length - 1] + 1);
    setDefaultPages([...defaultPages]);
  };

  const removePrev = (): void => {
    defaultPages.shift();
    setDefaultPages([...defaultPages]);
  };

  const addPrev = (): void => {
    defaultPages.unshift(defaultPages[startPage - 1] - 1);
    setDefaultPages([...defaultPages]);
  };

  const removeNext = (): void => {
    defaultPages.pop();
    setDefaultPages([...defaultPages]);
  };

  useEffect(() => {
    setPage(page);
    props.getPageNumber(page);
  }, []);

  useEffect(() => {
    setPage(props.reSetPage);
  }, [props.reSetPage]);

  useEffect(() => {
    if (page === 0) {
      setCurrentPage(startPage);
      if (props.totalPages > props.maxPages) {
        const pageArray = Array.from(
          { length: props.maxPages },
          (v, i) => i + 1
        );
        setDefaultPages([...pageArray]);
      } else {
        const pageArray = Array.from(
          { length: props.totalPages },
          (v, i) => i + 1
        );
        setDefaultPages([...pageArray]);
      }
    }
  }, [props.totalPages]);

  const onPageChange = (pageNumber: number, type: string): void => {
    setPage(pageNumber);
    if (type === 'prev') {
      const currentPageNumber = pageNumber !== 0 ? pageNumber + 1 : 1;
      setCurrentPage(currentPageNumber);
      if (
        currentPageNumber > 1 &&
        currentPageNumber < defaultPages[defaultPages.length - 1] - 1
      ) {
        addPrev();
        removeNext();
      }
    } else if (type === 'next') {
      const currentPageNumber = pageNumber + 1;
      setCurrentPage(currentPageNumber);
      if (
        props.totalPages > currentPageNumber &&
        currentPageNumber === defaultPages[defaultPages.length - 1]
      ) {
        addNext();
        removePrev();
      }
    }
    props.getPageNumber(pageNumber);
  };

  const onPageClick = (pageNumber: number, index: number): void => {
    if (pageNumber === currentPage) return;
    const pageValue = pageNumber - 1;
    setPage(pageValue);
    props.getPageNumber(pageValue);
    setCurrentPage(pageNumber);
    if (index === startPage - 1 && pageNumber !== startPage) {
      addPrev();
      removeNext();
    } else if (
      index === props.maxPages - 1 &&
      pageNumber !== props.totalPages
    ) {
      addNext();
      removePrev();
    }
  };

  return (
    <div>
      {defaultPages.length > 0 && (
        <Col sm={12} className="mt20">
          <Pagination className="custom_pagination">
            <Pagination.Prev
              disabled={page === 0}
              className="pagination_pre"
              onClick={() => onPageChange(page - 1, 'prev')}
            />
            {defaultPages.map((pageNumber: number, index: number) => {
              return (
                <Pagination.Item
                  key={index}
                  active={pageNumber === currentPage}
                  onClick={() => onPageClick(pageNumber, index)}
                >
                  {pageNumber}
                </Pagination.Item>
              );
            })}
            <Pagination.Next
              disabled={page === props.totalPages - 1}
              className="pagination_next"
              onClick={() => onPageChange(page + 1, 'next')}
            />
          </Pagination>
        </Col>
      )}
    </div>
  );
};

export default PaginationCommon;
