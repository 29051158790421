export const downloadFiles = (fileName: string, blobData: string): any => {
  const blob = new Blob([blobData], {
    type: 'application/octet-stream',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
