import React from 'react';
import { connect } from 'react-redux';
import {
  AuthInitialStateTypes,
  ConfigInitialStateTypes,
  ReceiptData,
} from '../types/reducerTypes';
import {
  GET_RECEIPT_DATA,
  DOWNLOAD_RECEIPT,
} from '../saga/receipt/receiptActionTypes';
import { ANGULAR_AUTH_SUCCESS } from '../saga/login/loginActionTypes';
import { withRouter } from 'react-router';
import translation from '../utils/language';
import ReceiptScreen from '../components/receipt/receipt';
import { Dispatch } from 'redux';
import { printLog } from '../utils/logging';
import {
  postMessage,
  sendMessage,
  Events,
  EventName,
} from '../utils/eventHandler';

interface Props {
  authentication: AuthInitialStateTypes;
  config: ConfigInitialStateTypes;
  receiptData: ReceiptData;
  getReceiptData: (params: any) => void;
  setAuthToken: (Object: any) => void;
  getReceiptDownload: (params: any) => void;
}

interface Payload {
  partnerCode: string;
  fromDate: string;
  toDate: string;
  page: number;
}

interface DownloadPayload {
  partnerCode: string;
  fromDate: string;
  toDate: string;
}

class Report extends React.Component<Props> {
  screenName: string;
  partnerCode: string;
  initialFromDate: string;
  initialToDate: string;
  constructor(props: Props) {
    super(props);
    this.screenName = translation.REPORT_SCREEN;
    this.partnerCode = '';
    this.initialFromDate = '';
    this.initialToDate = '';

    this.receiveMessage = this.receiveMessage.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.downloadReceipt = this.downloadReceipt.bind(this);
  }

  componentDidMount(): void {
    Events.on(EventName.ANGULAR_INIT_DATA, this.receiveMessage);
    sendMessage(postMessage);
  }

  receiveMessage = function (this: Report, receiveData: any): void {
    try {
      this.partnerCode = receiveData.partnerCode;
      this.props.setAuthToken(receiveData.authToken);
    } catch (error) {
      printLog(error);
    }
  };

  changeDate = function (
    this: Report,
    fromDate: string,
    toDate: string,
    page: number
  ): void {
    if (this.partnerCode) {
      const data = {
        partnerCode: this.partnerCode,
        fromDate: fromDate,
        toDate: toDate,
        page: page,
      };
      this.props.getReceiptData(data);
    }
  };

  downloadReceipt = function (
    this: Report,
    fromDate: string,
    toDate: string
  ): void {
    const data = {
      partnerCode: this.partnerCode,
      fromDate: fromDate,
      toDate: toDate,
    };
    this.props.getReceiptDownload(data);
  };
  render(): React.ReactNode {
    return (
      <>
        <ReceiptScreen
          receiptData={this.props.receiptData}
          changeDate={this.changeDate}
          downloadReceipt={this.downloadReceipt}
        />
      </>
    );
  }
}
const mapStateToProps = (props: Props): object => {
  return {
    authentication: props.authentication,
    config: props.config,
    receiptData: props.receiptData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
  return {
    getReceiptData: (params: Payload) => {
      dispatch({ type: GET_RECEIPT_DATA, params });
    },
    setAuthToken: (params: string) => {
      dispatch({ type: ANGULAR_AUTH_SUCCESS, params });
    },
    getReceiptDownload: (params: DownloadPayload) => {
      dispatch({ type: DOWNLOAD_RECEIPT, params });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Report) as any
);
