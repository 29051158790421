import { authenticationReducer } from '../saga/login/loginReducers';
import { configReducer } from '../saga/config/configReducer';
import { receiptReducer } from '../saga/receipt/receiptReducers';
import { combineReducers } from 'redux';
import { CLEAR_STORE } from '../saga/login/loginActionTypes';

export const appReducer = combineReducers({
  authentication: authenticationReducer,
  config: configReducer,
  receiptData: receiptReducer,
});

export const rootReducer = (state: any, action: any): any => {
  if (action.type === CLEAR_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};
